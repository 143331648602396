import {Heading, Text, VStack} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import {Config} from 'models'
import React from 'react'
import {IoDiamondOutline} from 'react-icons/io5'
import {RiLockLine} from 'react-icons/ri'
import strapi from '../api'
import {handleError} from '../components/Common/handleError'
import {$, k} from '../i18n/localization'
import Store from '../utils/Store'
import useModals from './useModals'

export enum Phase {
  DancerSelection,
  SchoolSelection,
  PublishResults,
}

export const phases = [
  {subtitle: $(k.dancerSelectionPhase), unit: $(k.dancers), icon: <RiLockLine />},
  {subtitle: $(k.schoolSelectionPhase), unit: $(k.schools), icon: <RiLockLine />},
  {subtitle: $(k.publishResultsPhase), icon: <IoDiamondOutline />},
]

const usePhaseData = () => {
  const dancers = Store.dancers.use()
  const schools = Store.schools.use()
  const phaseIndex = Store.phaseIndex.use()
  const {confirm} = useModals()
  const currentDancer = Store.currentDancer.use()
  const currentSchool = Store.currentSchool.use()
  const dancerPhaseOver = phaseIndex > Phase.DancerSelection
  const disableDancerEditFeatures = dancerPhaseOver || currentDancer?.confirmed
  const schoolPhaseOver = phaseIndex > Phase.SchoolSelection
  const disableSchoolEditFeatures = schoolPhaseOver || currentSchool?.confirmed
  const disableAdminEditFeatures = phaseIndex > Phase.PublishResults

  const onLock = async () => {
    const phase = phases[phaseIndex]
    const title =
      phaseIndex < Phase.PublishResults
        ? `${$(k.lockSelectionFor)} ${phase.unit}`
        : $(k.publishResults)
    const message =
      phaseIndex < Phase.PublishResults ? $(k.sureToPublish, phase.unit) : $(k.sureToPublishResults)
    const okText = (
      phaseIndex < Phase.PublishResults ? $(k.lockSelection) : $(k.publishResults)
    ) as string
    const variant = phaseIndex < Phase.PublishResults ? 'primary' : 'gold'
    if (
      !(await confirm(
        <>
          <Text as='span' display='block' fontWeight='bold' color='brand.text03' mb={5}>
            {title}
          </Text>
          <Text as='span' display='block'>
            {message}
          </Text>
        </>,
        {
          okButtonProps: {variant, rightIcon: phase.icon, flex: 1},
          okText,
          cancelButtonProps: {variant: 'outline', flex: 1},
        },
      ))
    )
      return
    try {
      if (phaseIndex !== Phase.PublishResults + 1) {
        await strapi.update<Config>('config', '', {phaseIndex: phaseIndex + 1})
        Store.phaseIndex.set(phaseIndex + 1)
      }
    } catch (e) {
      handleError(e)
    }
  }

  const PhaseDetails = () => {
    switch (phaseIndex) {
      case Phase.DancerSelection:
        return (
          <VStack p={2}>
            <Heading>
              {dancers.length} {$(k.dancers)}
            </Heading>
            <Text color='brand.text02'>
              {dancers.filter(d => !isEmpty(d.schools)).length} selections
            </Text>
          </VStack>
        )
      case Phase.SchoolSelection:
        return (
          <VStack p={2}>
            <Heading>
              {schools.length} {$(k.schools)}
            </Heading>
            <Text color='brand.text02'>
              {schools.filter(d => !isEmpty(d.selectedDancers)).length} {$(k.selections)}
            </Text>
          </VStack>
        )
      case Phase.PublishResults:
        return (
          <Text color='brand.text02' textAlign='center'>
            {$(k.sureToPublishResultsDesc)}
          </Text>
        )
    }
  }

  return {
    phaseIndex,
    PhaseDetails,
    onLock,
    disableDancerEditFeatures,
    disableSchoolEditFeatures,
    disableAdminEditFeatures,
    dancerPhaseOver,
    schoolPhaseOver,
  }
}

export default usePhaseData
