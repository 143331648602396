import {Box, Button, Grid, Heading, Image} from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import {$, k} from '../i18n/localization'

const Page404 = () => {
  const navigate = useNavigate()

  const handleBackToHome = () => navigate('/')

  return (
    <Grid gap={4} textAlign='center'>
      <Heading>Page not Found</Heading>

      <Box maxWidth={[280, 400]} marginX='auto'>
        <Image width={400} src='/assets/404 Error-rafiki.svg' />
      </Box>

      <Box>
        <Button onClick={handleBackToHome}>{$(k.back)}</Button>
      </Box>
    </Grid>
  )
}

export default Page404
