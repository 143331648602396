import {Box, HStack, Link, Text, useTheme, VStack} from '@chakra-ui/react'
import {School} from 'models'
import React, {Children} from 'react'
import BackButton from '../../components/Common/BackButton'
import MatchingSchoolItem from '../../components/Dancer/MatchingSchoolItem'
import MobileLayout from '../../components/Mobile/MobileLayout'
import MobileSchoolItem from '../../components/Mobile/MobileSchoolItem'
import {$, k} from '../../i18n/localization'
import Store from '../../utils/Store'
import {Dancer_Plain} from 'models/dancer'
import {School_Plain} from 'models/school'

export const DancersMatches = ({dancer}: {dancer: Dancer_Plain}) => {
  const theme = useTheme()
  const {bidirectionalMatches} = getDancerMatches(dancer)

  if (bidirectionalMatches?.length === 0) return null
  return (
    <VStack w='full' alignItems='start'>
      <HStack justify='space-between' fontSize='sm' w='full'>
        <Text color={theme.colors.brand.text01} fontWeight='bold'>
          {$(k.yourMatches)}
        </Text>
        <Text>{bidirectionalMatches.length}</Text>
      </HStack>

      <Box
        w='full'
        bgColor='brand.matchingBg'
        border='solid 1px'
        borderRadius='xl'
        px={4}
        borderColor='brand.accent'>
        <VStack bgColor='brand.accent' spacing='1px'>
          {bidirectionalMatches.map(school => (
            <MatchingSchoolItem key={school.id} school={school} />
          ))}
        </VStack>
      </Box>
    </VStack>
  )
}

export const getDancerMatches = (currentDancer: Dancer_Plain) => {
  const schools = Store.schools?.use()
  const allDancerSchoolsOfInterest = currentDancer?.schools || []
  const currentDancerSchoolsSelection =
    schools
      ?.map(school => {
        if (school.selectedDancers.find(d => d.id === currentDancer.id)) {
          return school
        }
        return null
      })
      .filter(Boolean) ?? ([] as School[])
  const currentDancerSchoolsSelectionIds = currentDancerSchoolsSelection?.map(s => s?.id)
  const bidirectionalMatches =
    allDancerSchoolsOfInterest?.filter(s => currentDancerSchoolsSelectionIds.includes(s.id)) ?? []

  return {bidirectionalMatches, currentDancerSchoolsSelection}
}

export const getDancerOtherInterestedSchools = (dancer: Dancer_Plain) => {
  const {bidirectionalMatches, currentDancerSchoolsSelection} = getDancerMatches(dancer)
  const otherInterestedSchools = currentDancerSchoolsSelection.filter(
    s => s && !bidirectionalMatches.map(s => s.id).includes(s.id),
  ) as School_Plain[]
  return otherInterestedSchools
}

export const DancerInterestedSchools = ({dancer}: {dancer: Dancer_Plain}) => {
  const theme = useTheme()
  const otherInterestedSchools = getDancerOtherInterestedSchools(dancer)

  if (otherInterestedSchools?.length === 0) return null

  return (
    <VStack w='full' alignItems='start'>
      <HStack justify='space-between' fontSize='sm' py={3} w='full'>
        <Text color={theme.colors.brand.text01} fontWeight='bold'>
          {$(k.otherInterestedSchools)}
        </Text>
        <Text>{otherInterestedSchools.length}</Text>
      </HStack>

      <VStack spacing='1px' bgColor='brand.surface02' w='full'>
        {Children.toArray(
          otherInterestedSchools.map(school => <MobileSchoolItem school={school} />),
        )}
      </VStack>
    </VStack>
  )
}
const DancerResults = () => {
  const config = Store.config.use()
  const dancer = Store.currentDancer.use()
  return (
    <MobileLayout header={<BackButton />}>
      <VStack px={5} w='full' alignItems='start'>
        <DancersMatches dancer={dancer} />
        <DancerInterestedSchools dancer={dancer} />

        <Link w='full' textAlign='center' cursor='normal' color='brand.text04'>
          {config.noticeProblemMessage}
        </Link>
      </VStack>
    </MobileLayout>
  )
}

export default DancerResults
