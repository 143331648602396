export function hexToRGBA(hex: string, alpha = 1) {
  // Ensure the hex value is formatted properly
  hex = hex.replace('#', '')

  if (hex.length !== 3 && hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }

  // If it's a shorthand hex code, expand it
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('')
  }

  // Parse r, g, b values
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
