import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  IconButton,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import {ROUTE_SCHOOL} from 'lib/router/routes'
import {smoothClasses} from 'lib/styles/theme/CustomCheckbox'
import {School} from 'models'
import {FaArrowRight, FaCheckCircle} from 'react-icons/fa'
import {RiBankLine, RiDraggable, RiLockLine, RiUserLine} from 'react-icons/ri'
import {Case, Switch} from 'react-if'
import {useNavigate} from 'react-router-dom'
import usePhaseData from '../../hooks/usePhaseData'
import {$, k} from '../../i18n/localization'
import {ItemWrapper} from '../Common/ItemWrapper'

interface Props {
  school: School
  selected?: boolean
  draggable?: boolean
  checkbox?: boolean
  hideArrow?: boolean
}

const SchoolItem = (props: Props) => {
  const {school, selected, draggable, checkbox} = props
  const navigate = useNavigate()
  const theme = useTheme()
  const {disableAdminEditFeatures, disableSchoolEditFeatures} = usePhaseData()
  const schoolLink = ROUTE_SCHOOL.replace(':id', school.id.toString())

  return (
    <ItemWrapper {...props}>
      <HStack spacing={3}>
        <Switch>
          <Case condition={draggable && !disableAdminEditFeatures}>
            <RiDraggable />
          </Case>
          <Case condition={!draggable}>
            <RiBankLine />
          </Case>
        </Switch>
        <Avatar src={school.picture} icon={<RiBankLine />} loading='lazy' />

        <VStack alignItems='start'>
          <Heading as='h3'>{school.name}</Heading>
          <HStack fontSize='sm'>
            <Text whiteSpace='nowrap'>{school.country}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{school.minAge}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>
              {school.languages?.map((language: string) => language?.substring(0, 2))?.join('/')}
            </Text>
          </HStack>
          <HStack>
            {!draggable && (
              <Button size='sm' leftIcon={<RiUserLine />} fontWeight='normal'>
                {school.dancers?.length || 0}
              </Button>
            )}
            {disableSchoolEditFeatures ? (
              <Button variant='secondary' size='sm' leftIcon={<RiLockLine />} fontWeight='normal'>
                {$(k.locked)}
              </Button>
            ) : school.confirmed ? (
              <HStack spacing={1}>
                <FaCheckCircle color={theme.colors.brand.success} />
                <Text color={theme.colors.brand.success}>{$(k.confirmed)}</Text>
              </HStack>
            ) : null}
          </HStack>
        </VStack>
      </HStack>
      <HStack>
        {!props.hideArrow && (
          <Box onClick={() => navigate(schoolLink)}>
            <IconButton
              aria-label='go to school'
              variant='linkGold'
              size='sm'
              icon={<FaArrowRight />}
            />
          </Box>
        )}
        {checkbox && <Checkbox isChecked={selected} sx={smoothClasses} pointerEvents='none' />}
      </HStack>
    </ItemWrapper>
  )
}

export default SchoolItem
