import dayjs from 'dayjs'
import {Dancer} from 'models'
import {Group} from 'models/dancer'
import {$, k} from '../i18n/localization'

export const getAge = (dancer: Dancer) =>
  Math.ceil(dayjs().diff(dayjs(dancer.Birthdate), 'y', true) * 2) / 2

export const getSeniority = (dancer: Dancer) => {
  if (dancer.Group) {
    if ([Group.GirlsA, Group.BoysA].includes(dancer.Group)) return $(k.junior)
    if ([Group.GirlsB, Group.BoysB].includes(dancer.Group)) return $(k.senior)
  }
  return '-'
}
