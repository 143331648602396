import {ColorModeScript} from '@chakra-ui/react'
import {theme} from 'lib/styles/theme'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <ColorModeScript initialColorMode={theme.config?.initialColorMode} />
    <App />
  </>,
)
