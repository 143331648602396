import {Box, Center, Divider, Heading, HStack, Text, VStack} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import {SchoolProps} from 'models/interfaces'
import {IoDiamondOutline} from 'react-icons/io5'
import {Case, Default, Switch} from 'react-if'
import {$, k} from '../../i18n/localization'
import {FLOATING_HEADER_HEIGHT} from '../../styles/theme'
import MatchingDancerItem from '../School/MatchingDancerItem'

const DancerCalculatedResults = ({school}: SchoolProps) => {
  const dancersIds = school.dancers.use().map(s => s.id)
  const bidirectionalMatches = school.selectedDancers.use().filter(s => dancersIds.includes(s.id))

  return (
    <VStack alignItems='start' w='full' spacing={4}>
      <HStack pos='sticky' top={0} bgColor='white' w='full' zIndex={1} h={FLOATING_HEADER_HEIGHT}>
        <IoDiamondOutline />
        <Heading>{$(k.calculatedResults)}</Heading>
      </HStack>
      <Center px={2} w='full'>
        <Box
          w='full'
          bgColor='brand.matchingBg'
          border='solid 1px'
          borderRadius='xl'
          px={4}
          borderColor='brand.accent'>
          <Switch>
            <Case condition={isEmpty(bidirectionalMatches)}>
              <HStack h='82px'>
                <Text color='brand.accent'>Currently no matches</Text>
              </HStack>
            </Case>
            <Default>
              <VStack bgColor='brand.accent' spacing='1px'>
                {bidirectionalMatches.map(dancer => (
                  <MatchingDancerItem key={dancer.id} dancer={dancer} />
                ))}
              </VStack>
            </Default>
          </Switch>
        </Box>
      </Center>
      <Divider />
    </VStack>
  )
}

export default DancerCalculatedResults
