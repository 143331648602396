import {
  Center,
  CenterProps,
  Fade,
  Image,
  ScaleFade,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
// import Lottie from 'react-lottie'
// import animationData from './animation_lln2xzc7.json'
// import image_logo from './logo_loading.png'
import image_logo from './logo_loading.png'

const LoadingAnimation = (props: CenterProps) => {
  return (
    <Center w='full' {...props}>
      <VStack p={0} maxW='500px' w='500px' alignContent='center' pos='relative'>
        {/* use only image for now with animation fade-in out (to 20%) */}
        <ScaleFade in={true} unmountOnExit={true} initialScale={0.9}>
          <Image
            src={image_logo}
            alt='Forum Prix de Lausanne'
            width={500}
            height={500}
            style={{
              objectFit: 'contain',
            }}
          />
        </ScaleFade>
      </VStack>
    </Center>
  )
}

export default LoadingAnimation
