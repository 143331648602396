import {Flex, HStack} from '@chakra-ui/react'
import {Dancer} from 'models'
import {SingleChildrenProps} from 'models/interfaces'
import {Role} from '../../models/Role'
import Store from '../../utils/Store'
import {Link} from 'react-router-dom'

interface Props extends SingleChildrenProps {
  selected?: boolean
  draggable?: boolean
  onAddInterested?: (dancer: Dancer) => void
  linkTo?: string
}

const ItemWrapper = ({children, selected, draggable, onAddInterested, linkTo}: Props) => {
  const role = Store.role.use()
  const isAdmin = role === Role.SUPER_ADMIN

  return (
    <Flex
      w='full'
      borderBottom='solid 1px white'
      bgColor={selected && isAdmin ? 'brand.surface02' : 'white'}
      _hover={{
        bgColor: !draggable && !onAddInterested && isAdmin ? 'brand.surface02' : undefined,
      }}
      {...(linkTo &&
        !draggable && {
          as: Link,
          to: linkTo,
        })}>
      <HStack justifyContent='space-between' w='full' px={[4]} py={[2, 4]} overflowX='hidden'>
        {children}
      </HStack>
    </Flex>
  )
}

export {ItemWrapper}
