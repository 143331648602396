import {enableReactUse} from '@legendapp/state/config/enableReactUse'
import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import AnimatedRouter from './lib/router/AnimatedRouter'
import Providers from './lib/utils/Providers'
import Store from './lib/utils/Store'

import './lib/sentry'

enableReactUse()

const App = () => {
  Store.loadAllData()

  return (
    <Providers>
      <BrowserRouter>
        <AnimatedRouter />
      </BrowserRouter>
    </Providers>
  )
}

export default App
