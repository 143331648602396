import isEmpty from 'lodash/isEmpty'
import strapi from '../api'
import Store from './Store'

export const swapArray = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const logout = () => {
  strapi.logout()
  strapi.removeToken()
  Store.isSignedIn.set(false)
  Store.role.set(undefined)
  Store.currentDancer.set(undefined)
  Store.currentSchool.set(undefined)
}

export const downloadStats = () => {
  downloadStatsFile(true)
  downloadStatsFile(false)
}
const downloadStatsFile = (matchMode?: boolean) => {
  const dancers = Store.dancers.get()
  const schools = Store.schools.get()
  const schoolsWithDancerIds = schools.map(school => ({
    ...school,
    dancersIds: school.dancers?.map(d => d.id) ?? [],
    selectedDancersIds: school.selectedDancers?.map(d => d.id) || [],
  }))

  let csvCell = ['Dossard', 'Prénom', 'Nom', 'Prix/Finalistes']
    .concat(schoolsWithDancerIds.map(school => school.name!))
    .concat([
      matchMode ? 'Total matches' : 'Total offers',
      matchMode ? 'Total matches finalists' : 'Total offers finalists',
    ])
  const csvRows = [csvCell.join(';')]
  dancers.forEach(dancer => {
    csvCell = [
      dancer.REF ?? '',
      dancer.Firstname ?? '',
      dancer.Lastname ?? '',
      isEmpty(dancer.Eligible_prix)
        ? ''
        : dancer.Eligible_prix?.includes('Finalist')
        ? 'Finalist'
        : dancer.Eligible_prix[0],
    ]
    schoolsWithDancerIds.forEach(school => {
      const bidirectionalMatch =
        school.selectedDancersIds.includes(dancer.id) &&
        (matchMode ? school.dancersIds.includes(dancer.id) : true)
      csvCell.push(bidirectionalMatch ? '1' : '')
    })
    const total = csvCell.filter(cell => cell === '1').length
    csvCell.push(`${total}`)
    csvCell.push(`${dancer.Eligible_prix?.includes('Finalist') ? total : ''}`)
    csvRows.push(csvCell.join(';'))
  })

  const csvData = new Blob([csvRows.join('\n')], {type: 'text/csv;charset=utf-8;'})
  const csvUrl = URL.createObjectURL(csvData)
  const hiddenElement = document.createElement('a')
  hiddenElement.href = csvUrl
  hiddenElement.target = '_blank'
  hiddenElement.download = matchMode
    ? 'Prix de Lausanne match stats export.csv'
    : 'Prix de Lausanne school selections stats export.csv'
  hiddenElement.click()
}
