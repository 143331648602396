import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import {ItemListProps} from 'models/interfaces'
import {useMemo, useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import {useParams} from 'react-router-dom'
import useItemList, {Mode} from '../../hooks/useItemList'
import Store from '../../utils/Store'

const ItemList = (props: ItemListProps) => {
  const {padding = 0, selectedSchools, selectedDancers, interestedDancers} = props
  const mode = Boolean(interestedDancers)
    ? Mode.SELECT_INTERESTED_DANCERS
    : Boolean(selectedSchools)
    ? Mode.SELECT_SCHOOL
    : Boolean(selectedDancers)
    ? Mode.SELECT_DANCER
    : Mode.LIST
  const [query, setQuery] = useState('')
  const theme = useTheme()
  const {placeholder, DancerWrapper, SchoolWrapper, toggleSelect} = useItemList({...props, mode})
  const {id} = useParams()
  const schools = Store.schools.use()
  const dancers = Store.dancers.use()

  const getSchools = useMemo(() => {
    if (mode === Mode.SELECT_INTERESTED_DANCERS) {
      return []
    }
    return mode !== Mode.SELECT_DANCER
      ? schools.filter(d => d.name?.toLowerCase().includes(query))
      : []
  }, [mode, query, schools])

  const getDancers = useMemo(() => {
    if (mode === Mode.SELECT_INTERESTED_DANCERS) {
      return interestedDancers!
    }
    return mode !== Mode.SELECT_SCHOOL
      ? dancers.filter(
          d =>
            d.Lastname?.toLowerCase().includes(query) ||
            d.Firstname?.toLowerCase().includes(query) ||
            d.REF?.startsWith(query),
        )
      : []
  }, [dancers, interestedDancers, mode, query])

  return (
    <VStack spacing={0}>
      {mode !== Mode.SELECT_INTERESTED_DANCERS && (
        <Box
          pos='sticky'
          top={0}
          bgColor='white'
          w='full'
          zIndex={1}
          py={`${padding}px`}
          px={[4, 0]}>
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <FaSearch color={theme.colors.brand.accent} />
            </InputLeftElement>
            <Input
              variant='search'
              type='search'
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder={placeholder as string}
            />
          </InputGroup>
        </Box>
      )}
      {![Mode.LIST, Mode.SELECT_INTERESTED_DANCERS].includes(mode) && (
        <HStack alignSelf='end' my={2} px={[4, 0]}>
          <Button size='sm' variant='outline' onClick={() => toggleSelect(true)}>
            Select all
          </Button>
          <Button size='sm' variant='outline' onClick={() => toggleSelect(false)}>
            Deselect all
          </Button>
        </HStack>
      )}
      <VStack spacing='1px' bgColor='brand.surface02' w='full'>
        {![Mode.SELECT_DANCER, Mode.SELECT_INTERESTED_DANCERS].includes(mode) &&
          getSchools.map(s => SchoolWrapper(s, id as string, props.hideArrow))}
        {![Mode.SELECT_SCHOOL].includes(mode) &&
          getDancers.map(d => DancerWrapper(d, id as string, props.hideArrow))}
      </VStack>
    </VStack>
  )
}

export default ItemList
