import {Box, Button, Flex, HStack, Image, Text, useTheme} from '@chakra-ui/react'
import {enableReactTracking} from '@legendapp/state/config/enableReactTracking'
import Store from 'lib/utils/Store'
import {AiOutlineFileExcel} from 'react-icons/ai'
import {RiLogoutBoxRLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import {$, k} from '../../i18n/localization'
import {ROUTE_ROOT} from '../../router/routes'
import {NAVBAR_HEIGHT} from '../../styles/theme'
import {downloadStats, logout} from '../../utils/dataHelper'
import PhaseStepper from './PhaseStepper'

enableReactTracking({
  auto: true,
})

const NavBar = () => {
  const isSignedIn = Store.isSignedIn.use()
  const theme = useTheme()

  return (
    <Box
      position='sticky'
      top={0}
      zIndex={1000}
      bgColor={theme.colors.brand.surface04}
      borderBottomColor={theme.colors.brand.separator}
      borderBottomWidth={1}
      w='full'
      px={5}
      py={2}>
      <Flex h={`${NAVBAR_HEIGHT}px`} alignItems='center' justifyContent='space-between'>
        <Link to={ROUTE_ROOT}>
          <HStack spacing={2}>
            <Image src='/assets/logo.svg' height='48px' alt='logo' />
            <Text color={theme.colors.brand.accent} fontWeight='bold'>
              Networking forum
            </Text>
          </HStack>
        </Link>
        <PhaseStepper />
        {isSignedIn && (
          <HStack>
            <Button
              color='brand.accent'
              variant='primary'
              onClick={downloadStats}
              leftIcon={<AiOutlineFileExcel />}>
              Export
            </Button>
            <Button
              color='brand.accent'
              variant='primary'
              onClick={logout}
              rightIcon={<RiLogoutBoxRLine />}>
              {$(k.logout)}
            </Button>
          </HStack>
        )}
      </Flex>
    </Box>
  )
}

export default NavBar
