import {extendTheme} from '@chakra-ui/react'
import colors from './colors'
import CustomButton from './CustomButton'
import CustomInput from './CustomInput'
import CustomMenu from './CustomMenu'
import globalStyles from './globalStyles'

export const theme = extendTheme({
  styles: globalStyles,
  colors,
  components: {
    Button: CustomButton,
    Input: CustomInput,
    Menu: CustomMenu,
  },
  initialColorMode: 'dark',
  useSystemColorMode: false,
})

export const TOAST_DURATION = 10_000
export const TRANSITION_DURATION = 0.4
export const springTransition = {
  type: 'spring',
  duration: 1,
  bounce: 0.3,
  ease: [-1, -0.23, -0.62, 0],
}
export const NAVBAR_HEIGHT = 56
export const NAVBAR_HEIGHT_WITH_PADDING = NAVBAR_HEIGHT + 40
export const FLOATING_HEADER_HEIGHT = 10
