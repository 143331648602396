import {useBreakpointValue, useTheme, VStack} from '@chakra-ui/react'
import {Switch} from '@legendapp/state/react'
import {Outlet} from 'react-router-dom'
import {isDev} from '../../const'
import {Role} from '../../models/Role'
import '../../styles/globals.css'
import Store from '../../utils/Store'
import NavBar from './NavBar'
import {NotSupported} from './NotSupported'

const Layout = () => {
  const theme = useTheme()
  const role = Store.role.use()
  const isDesktop = useBreakpointValue({base: false, lg: true}, {fallback: 'lg'})
  const blockMobileViewForDancers = false && isDesktop // desactivate for now

  return (
    <VStack minH='100vh' bgColor={theme.colors.brand.background}>
      <Switch value={role}>
        {{
          [Role.SUPER_ADMIN]: () => (
            <>
              {isDesktop ? <NavBar /> : <> </>}
              {isDesktop ? <Outlet /> : <NotSupported platform='Mobile' />}
            </>
          ),
          [Role.DANCER]: () =>
            blockMobileViewForDancers ? <NotSupported platform='Desktop' /> : <Outlet />,
          default: () => <Outlet />,
        }}
      </Switch>
    </VStack>
  )
}

export default Layout
