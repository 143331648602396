import {menuAnatomy} from '@chakra-ui/anatomy'
import {createMultiStyleConfigHelpers} from '@chakra-ui/react'

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  list: {
    bg: 'brand.background',
  },
  item: {
    color: 'gray.200',
    bg: 'brand.background',
    _hover: {
      bg: 'brand.primary',
    },
    _focus: {
      bg: 'brand.primary',
    },
  },
})

const CustomMenu = defineMultiStyleConfig({baseStyle})

export default CustomMenu
