import {CSSProperties} from 'react'
import {DraggingStyle, NotDraggingStyle} from 'react-beautiful-dnd'

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  width: '100%',
  userSelect: 'none',
  marginBottom: 1,
  ...draggableStyle,
})

export const getListStyle = (
  isDraggingOver: boolean,
  bg: string,
  isDesktop: boolean = true,
): CSSProperties => ({
  width: '100%',
  background: isDraggingOver ? 'lightgrey' : bg,
  paddingBottom: isDraggingOver ? 82 : 0,
})
