import {ChakraProvider} from '@chakra-ui/react'
import {SingleChildrenProps} from 'models/interfaces'
import React from 'react'
import {ModalProvider} from '../hooks/useModals'
import {theme} from '../styles/theme'

const Providers = ({children}: SingleChildrenProps) => {
  return (
    <ChakraProvider theme={theme}>
      <ModalProvider>{children}</ModalProvider>
    </ChakraProvider>
  )
}

export default Providers
