import {observable} from '@legendapp/state'
import {configureObservablePersistence, persistObservable} from '@legendapp/state/persist'
import {ObservablePersistLocalStorage} from '@legendapp/state/persist-plugins/local-storage'
import {isProduction} from 'lib/const'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import {Config, Dancer, School} from 'models'
import strapi from '../api'
import {Role} from '../models/Role'
import {logout} from './dataHelper'

configureObservablePersistence({
  pluginLocal: ObservablePersistLocalStorage,
})

const dancers = observable<Dancer[]>([])
const schools = observable<School[]>([])
const allDataLoaded = observable<boolean>(false)
const phaseIndex = observable<number>(0)
const config = observable<Config>()
const isSignedIn = observable<boolean>(Boolean(strapi.getToken()))
const role = observable<Role>()
const tutorialDone = observable(false)
const selectedSchools = observable<School[]>([])
const selectedDancers = observable<Dancer[]>([])
const currentDancer = observable<Dancer>()
const currentSchool = observable<School>()

const preserveModalState = observable({
  isOpen: false,
  //not used for now but could be nice to preserve search too
  search: '',
})

const strapiParams = {
  populate: '*',
  pagination: {page: 0, pageSize: 500},
}

export type StoreType = typeof Store
const Store = {
  loadAllData: async () => {
    if (!isSignedIn.get()) {
      allDataLoaded.set(true)
      return
    }
    try {
      const configRes = (await strapi.findOne<Config>('config', '')).data
      phaseIndex.set(configRes.phaseIndex)
      config.set(configRes)
    } catch (e) {
      console.log('Error fetching config', e)
    }
    try {
      const user: any = await strapi.find('users/me', {populate: 'role'})
      const userRole = user.role.name
      role.set(userRole as Role)

      /**
       * SET DANCERS
       */
      if ([Role.SUPER_ADMIN, Role.SCHOOL].includes(userRole)) {
        const dancersResponse = await strapi.find<Dancer[]>('dancers', strapiParams)
        // console.log('dancersResponse', dancersResponse)
        const dancersSorted = sortBy(dancersResponse.data, ['REF', 'Firstname'])
        dancers.set(dancersSorted)
      } else {
        // IS DANCER
        const dancerResponse = await strapi.find<Dancer[]>('dancers', {
          filters: {Contact_email: {$eq: user.email}},
          ...strapiParams,
        })
        const dancerResponseList = dancerResponse.data
        if (isEmpty(dancerResponseList)) {
          throw new Error('No dancer found')
        } else {
          dancers.set(dancerResponseList)
          const firstDancer = dancerResponseList[0]
          currentDancer.set(firstDancer)
          selectedSchools.set(firstDancer.schools)
        }
      }

      /**
       * SET SCHOOLS
       */
      if ([Role.SUPER_ADMIN, Role.DANCER].includes(userRole)) {
        const schoolsResponse = await strapi.find<School[]>('schools', strapiParams)
        schools.set(
          sortBy(schoolsResponse.data, 'name').filter(school => {
            if (!isProduction) return true
            return school.email !== 'forumprixdelausanne+school@gmail.com' // .ie hide this school in prod
          })
        )
      } else {
        // IS SCHOOL
        const schoolResponse = await strapi.find<School[]>('schools', {
          filters: {email: {$eq: user.email}},
          ...strapiParams,
        })
        const schoolResponseList = schoolResponse.data
        if (isEmpty(schoolResponseList)) {
          throw new Error('No school found')
        } else {
          schools.set(schoolResponseList)
          const firstSchool = schoolResponseList[0]
          currentSchool.set(firstSchool)
          // firstSchool.selectedDancers has no populated school data
          // so pick the dancers from the dancer list
          const selectedDancersIds = firstSchool.selectedDancers.map(d => d.id)
          const dancersFromSchool = dancers.get().filter(d => selectedDancersIds.includes(d.id))
          selectedDancers.set(dancersFromSchool)
        }
      }
      allDataLoaded.set(true)
    } catch (e: any) {
      if (
        e.error?.status === 403 ||
        e?.message === 'No dancer found' ||
        e?.message === 'No school found'
      ) {
        logout()
      }
    }
  },
  schools,
  dancers,
  allDataLoaded,
  phaseIndex,
  isSignedIn,
  role,
  tutorialDone,
  selectedSchools,
  selectedDancers,
  currentDancer,
  currentSchool,
  config,
  preserveModalState,
}

Store.isSignedIn.onChange(() => Store.loadAllData())

Store.selectedSchools.onChange(value =>
  strapi.update<Dancer>('dancers', currentDancer.get()!.id, {schools: value.value})
)

Store.selectedDancers.onChange(value =>
  strapi.update<School>('schools', currentSchool.get()!.id, {selectedDancers: value.value})
)

persistObservable(tutorialDone, {
  local: 'tutorialDone',
})

persistObservable(role, {
  local: 'role',
})

export default Store
