import {HStack, IconButton, Text} from '@chakra-ui/react'
import {FaArrowLeft} from 'react-icons/fa6'
import {useLocation, useNavigate} from 'react-router-dom'
import {$, k} from '../../i18n/localization'
import {ROUTE_ROOT} from 'lib/router/routes'

const BackButton = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const doesAnyHistoryEntryExist = location.key !== 'default'
  const handleBack = () => {
    if (doesAnyHistoryEntryExist) navigate(-1)
    else navigate(ROUTE_ROOT, {replace: true})
  }

  return (
    <HStack onClick={handleBack}>
      <IconButton variant='secondary' icon={<FaArrowLeft />} aria-label='back' />
      <Text as='span' color='white'>
        {$(k.back)}
      </Text>
    </HStack>
  )
}

export default BackButton
