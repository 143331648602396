import {ObservableObject} from '@legendapp/state'
import {ReactNode} from 'react'
import {Dancer, School} from './index'

export interface SingleChildrenProps {
  children: ReactNode
}

export interface SchoolProps {
  school: ObservableObject<School>
}

export interface ItemListProps {
  padding?: number
  selectedSchools?: School[]
  setSelectedSchools?: (schools: School[]) => void
  selectedDancers?: Dancer[]
  setSelectedDancers?: (dancers: Dancer[]) => void
  interestedDancers?: Dancer[]
  onAddInterested?: (dancer: Dancer) => void
  hideArrow?: boolean
}

export const prizes = [
  'Prize Winner 1',
  'Prize Winner 2',
  'Prize Winner 3',
  'Prize Winner 4',
  'Prize Winner 5',
  'Prize Winner 6',
  'Prize Winner 7',
  'Prize Winner 8',
  'Prize Winner 9',
  'Prize Winner 10',
  'Prize Winner 11',
  'Finalist',
  'Best Swiss Candidate Award',
  'Best Young Talent Award',
  'Contemporary Dance Award',
  'Web Audience Favourite Award',
  'Audience Favourite Award',
]
