import {lazy} from 'react'
import type {PathRouteProps} from 'react-router-dom'

const Home = lazy(() => import('lib/pages/home'))
const SignIn = lazy(() => import('lib/pages/sign-in'))
const ForgotPassword = lazy(() => import('lib/pages/forgot-password'))
const ResetPassword = lazy(() => import('lib/pages/reset-password'))
const DancerDetails = lazy(() => import('lib/pages/dancers/id'))
const SchoolDetails = lazy(() => import('lib/pages/schools/id'))
const SortSchools = lazy(() => import('lib/pages/schools/sort-schools'))
const SortDancers = lazy(() => import('lib/pages/dancers/sort-dancers'))
const DancerResults = lazy(() => import('lib/pages/dancers/dancer-results'))
const SchoolResults = lazy(() => import('lib/pages/schools/school-results'))

export const ROUTE_ROOT = '/'
export const ROUTE_SIGN_IN = '/sign-in'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_RESET_PASSWORD = '/reset-password'
export const ROUTE_DETAILS = '/details'
export const ROUTE_DETAILS_BASE = (path: string) => `/details${path}`
export const ROUTE_DANCER = '/dancer/:id'
export const ROUTE_SCHOOL = '/school/:id'
export const ROUTE_SORT_SCHOOLS = '/sort-schools'
export const ROUTE_SORT_DANCERS = '/sort-dancers'
export const ROUTE_DANCER_RESULTS = '/dancer-results'
export const ROUTE_SCHOOL_RESULTS = '/school-results'

export const routes: Array<PathRouteProps> = [
  {
    path: ROUTE_SIGN_IN,
    element: <SignIn />,
  },
  {
    path: ROUTE_FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: ROUTE_RESET_PASSWORD,
    element: <ResetPassword />,
  },
]

export const privateRoutes: Array<PathRouteProps> = [
  {
    path: ROUTE_ROOT,
    element: <Home />,
  },
  {
    path: ROUTE_DANCER,
    element: <DancerDetails />,
  },
  {
    path: ROUTE_SCHOOL,
    element: <SchoolDetails />,
  },
  {
    path: ROUTE_SORT_SCHOOLS,
    element: <SortSchools />,
  },
  {
    path: ROUTE_SORT_DANCERS,
    element: <SortDancers />,
  },
  {
    path: ROUTE_DANCER_RESULTS,
    element: <DancerResults />,
  },
  {
    path: ROUTE_SCHOOL_RESULTS,
    element: <SchoolResults />,
  },
]
