import {enableReactTracking} from '@legendapp/state/config/enableReactTracking'
import {ReactElement, ReactNode} from 'react'
import {Navigate} from 'react-router-dom'
import {ROUTE_ROOT, ROUTE_SIGN_IN} from '../router/routes'
import Store from './Store'

enableReactTracking({
  auto: true,
})

type PrivateRouteProps = {
  children: ReactNode
  redirectTo?: string
}

export const RequireAuth = ({children, redirectTo = ROUTE_SIGN_IN}: PrivateRouteProps) => {
  const isSignedIn = Store.isSignedIn.use()
  return isSignedIn ? (children as ReactElement) : <Navigate to={redirectTo} />
}

export const HasAuth = ({children, redirectTo = ROUTE_ROOT}: PrivateRouteProps) => {
  const isSignedIn = Store.isSignedIn.use()
  return isSignedIn ? <Navigate to={redirectTo} /> : (children as ReactElement)
}
