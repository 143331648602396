// Interface automatically generated by schemas-to-ts

import {AdminPanelRelationPropertyModification} from './AdminPanelRelationPropertyModification'
import {School, School_Plain} from './school'

export enum Group {
  GirlsA = 'Girls A',
  GirlsB = 'Girls B',
  BoysA = 'Boys A',
  BoysB = 'Boys B',
}
export enum Gender {
  Female = 'female',
  Male = 'male',
}
export enum Size {
  Xs = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  Xl = 'XL',
}

export interface Dancer {
  id: number
  attributes: {
    createdAt: Date
    updatedAt: Date
    publishedAt?: Date
    REF?: string
    Group?: Group
    Firstname?: string
    Lastname?: string
    Birthdate?: Date
    Height?: number
    Gender?: Gender
    Nationalities?: string
    TikTok_username?: string
    Facebook_username?: string
    Instagram_username?: string
    Contact_email?: string
    yearsOfBallet?: number
    Weekly_hours_of_ballet_classes?: number
    Main_ballet_teacher?: string
    Years_of_contemporary_class?: number
    Weekly_hours_of_contemporary_classes?: number
    Contemporary_teacher?: string
    Classical_variation?: string
    Classical_variation_teacher?: string
    Contemporary_variation?: string
    Contemporary_variation_teacher?: string
    English_well_enough?: boolean
    Translation_requirements?: string
    Size?: Size
    Consent_rules_regulations?: boolean
    PRIXSUISSE?: boolean
    Profile_Picture?: string
    Languages?: string
    No_professional_contract?: boolean
    For_partner_school_candidates_want_to_remain_current_school?: boolean
    schools?: {data: School[]}
    confirmed?: boolean
    Eligible_prix?: any
    Current_school?: string
  }
}
export interface Dancer_Plain {
  id: number
  createdAt: Date
  updatedAt: Date
  publishedAt?: Date
  REF?: string
  Group?: Group
  Firstname?: string
  Lastname?: string
  Birthdate?: Date
  Height?: number
  Gender?: Gender
  Nationalities?: string
  TikTok_username?: string
  Facebook_username?: string
  Instagram_username?: string
  Contact_email?: string
  yearsOfBallet?: number
  Weekly_hours_of_ballet_classes?: number
  Main_ballet_teacher?: string
  Years_of_contemporary_class?: number
  Weekly_hours_of_contemporary_classes?: number
  Contemporary_teacher?: string
  Classical_variation?: string
  Classical_variation_teacher?: string
  Contemporary_variation?: string
  Contemporary_variation_teacher?: string
  English_well_enough?: boolean
  Translation_requirements?: string
  Size?: Size
  Consent_rules_regulations?: boolean
  PRIXSUISSE?: boolean
  Profile_Picture?: string
  Languages?: string
  No_professional_contract?: boolean
  For_partner_school_candidates_want_to_remain_current_school?: boolean
  schools?: School_Plain[]
  confirmed?: boolean
  Eligible_prix?: any
  Current_school?: string
}

export interface Dancer_NoRelations {
  id: number
  createdAt: Date
  updatedAt: Date
  publishedAt?: Date
  REF?: string
  Group?: Group
  Firstname?: string
  Lastname?: string
  Birthdate?: Date
  Height?: number
  Gender?: Gender
  Nationalities?: string
  TikTok_username?: string
  Facebook_username?: string
  Instagram_username?: string
  Contact_email?: string
  yearsOfBallet?: number
  Weekly_hours_of_ballet_classes?: number
  Main_ballet_teacher?: string
  Years_of_contemporary_class?: number
  Weekly_hours_of_contemporary_classes?: number
  Contemporary_teacher?: string
  Classical_variation?: string
  Classical_variation_teacher?: string
  Contemporary_variation?: string
  Contemporary_variation_teacher?: string
  English_well_enough?: boolean
  Translation_requirements?: string
  Size?: Size
  Consent_rules_regulations?: boolean
  PRIXSUISSE?: boolean
  Profile_Picture?: string
  Languages?: string
  No_professional_contract?: boolean
  For_partner_school_candidates_want_to_remain_current_school?: boolean
  schools?: number[]
  confirmed?: boolean
  Eligible_prix?: any
  Current_school?: string
}

export interface Dancer_AdminPanelLifeCycle {
  id: number
  createdAt: Date
  updatedAt: Date
  publishedAt?: Date
  REF?: string
  Group?: Group
  Firstname?: string
  Lastname?: string
  Birthdate?: Date
  Height?: number
  Gender?: Gender
  Nationalities?: string
  TikTok_username?: string
  Facebook_username?: string
  Instagram_username?: string
  Contact_email?: string
  yearsOfBallet?: number
  Weekly_hours_of_ballet_classes?: number
  Main_ballet_teacher?: string
  Years_of_contemporary_class?: number
  Weekly_hours_of_contemporary_classes?: number
  Contemporary_teacher?: string
  Classical_variation?: string
  Classical_variation_teacher?: string
  Contemporary_variation?: string
  Contemporary_variation_teacher?: string
  English_well_enough?: boolean
  Translation_requirements?: string
  Size?: Size
  Consent_rules_regulations?: boolean
  PRIXSUISSE?: boolean
  Profile_Picture?: string
  Languages?: string
  No_professional_contract?: boolean
  For_partner_school_candidates_want_to_remain_current_school?: boolean
  schools?: AdminPanelRelationPropertyModification<School_Plain>
  confirmed?: boolean
  Eligible_prix?: any
  Current_school?: string
}
