import {AspectRatio, Avatar, Box, Center, Heading, HStack, Text, VStack} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import {Dancer, School} from 'models'
import {SchoolProps} from 'models/interfaces'
import {RiUserLine} from 'react-icons/ri'
import strapi from '../../api'
import {$, k} from '../../i18n/localization'
import {FLOATING_HEADER_HEIGHT} from '../../styles/theme'
import ItemList from '../Common/ItemList'

const DancerInterested = ({school}: SchoolProps) => {
  const dancers = school.dancers.use()
  const selectedDancers = school.selectedDancers.use()
  const sch = school.get()

  const onAddInterested = async (dancer: Dancer) => {
    const newDancers = [...school.selectedDancers.get(), dancer]
    school.selectedDancers.set(newDancers)
    await strapi.update<School>('schools', sch.id, {
      selectedDancers: newDancers.map(s => s.id),
    })
  }

  const interestedDancers = dancers
    .filter(d => !selectedDancers.map(d => d.id).includes(d.id))
    .map(d => ({...d, schools: [sch]}))

  return (
    <VStack alignItems='start' w='full' spacing={4}>
      <HStack pos='sticky' top={0} bgColor='white' w='full' zIndex={1} h={FLOATING_HEADER_HEIGHT}>
        <Heading>{$(k.interestedDancers)}</Heading>
      </HStack>
      <Center px={2} w='full'>
        <Box w='full' boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'>
          {isEmpty(interestedDancers) ? (
            <Center p={10}>
              <VStack>
                <AspectRatio ratio={1} w='100px'>
                  <Avatar borderRadius='full' icon={<RiUserLine size='60' />} />
                </AspectRatio>
                <Text>
                  No {isEmpty(dancers) ? '' : $(k.additional)} {$(k.interestedDancersDesc)}
                </Text>
              </VStack>
            </Center>
          ) : (
            <ItemList
              interestedDancers={sortBy(interestedDancers, 'Firstname')}
              onAddInterested={onAddInterested}
            />
          )}
        </Box>
      </Center>
    </VStack>
  )
}

export default DancerInterested
