import {createStandaloneToast} from '@chakra-ui/react'
import {isDev} from '../../const'
import {$, k} from '../../i18n/localization'
import {theme} from '../../styles/theme'

export const handleError = (
  e: any,
  description: string | null | any[] = null,
  callback: (() => void) | null = null,
) => {
  const {toast} = createStandaloneToast({theme})
  isDev && console.error(e)
  let message = description || $(k.genericError)
  if (e && !description) {
    if (e.response) {
      message = e.response.message
    } else if (e.message) {
      message = e.message
    }
  }
  toast({
    title: $(k.error),
    description: message,
    status: 'error',
    duration: 10000,
    isClosable: true,
    variant: 'subtle',
  })
  if (callback) callback()
}

export const {toast: standaloneToast}: any = createStandaloneToast({theme})
