import {Box, Link, useBreakpointValue} from '@chakra-ui/react'
import {Dancer, School} from 'models'
import {ItemListProps} from 'models/interfaces'
import {useLocation, useNavigate} from 'react-router-dom'
import DancerItem from '../components/Dancer/DancerItem'
import MobileDancerItem from '../components/Mobile/MobileDancerItem'
import MobileSchoolItem from '../components/Mobile/MobileSchoolItem'
import SchoolItem from '../components/School/SchoolItem'
import {$, k} from '../i18n/localization'
import {ROUTE_DANCER, ROUTE_DETAILS_BASE, ROUTE_SCHOOL} from '../router/routes'
import Store from '../utils/Store'

export enum Mode {
  LIST, // Admin mode
  SELECT_DANCER,
  SELECT_SCHOOL,
  SELECT_INTERESTED_DANCERS,
}

const useItemList = (props: ItemListProps & {mode: Mode}) => {
  const {
    selectedSchools,
    setSelectedSchools,
    selectedDancers,
    setSelectedDancers,
    onAddInterested,
    mode,
  } = props
  const location = useLocation()
  const dancers = Store.dancers
  const schools = Store.schools
  const navigate = useNavigate()
  const isDesktop = useBreakpointValue({base: false, lg: true}, {fallback: 'lg'})
  const currentSchool = Store.currentSchool.use()
  const placeholder =
    mode === Mode.LIST
      ? $(k.searchSchoolsOrDancers)
      : mode === Mode.SELECT_SCHOOL
      ? $(k.searchSchools)
      : $(k.searchDancers)

  const toggleSelect = (selectAll: boolean) => {
    switch (mode) {
      case Mode.SELECT_SCHOOL:
        setSelectedSchools!(selectAll ? schools.get() : [])
        break
      case Mode.SELECT_DANCER:
        setSelectedDancers!(selectAll ? dancers.get() : [])
        break
    }
  }

  const onSchoolClicked = (school: School) => {
    if (mode === Mode.LIST) {
      const route = ROUTE_DETAILS_BASE(ROUTE_SCHOOL.replace(':id', school.id.toString()))
      navigate(route)
    } else {
      setSelectedSchools!(
        selectedSchools!.find(s => s.id === school.id)
          ? selectedSchools!.filter(s => s.id !== school.id)
          : [...selectedSchools!, school],
      )
    }
  }

  const SchoolWrapper = (school: School, id: string, hideArrow?: boolean) => {
    let current
    if (mode === Mode.LIST) {
      current = location.pathname.includes('school') && school.id.toString() === id
    } else {
      current = Boolean(selectedSchools?.find(i => i.id === school.id))
    }

    const key = `school_${school.id}`

    return (
      <Box key={key} as={isDesktop ? Link : Box} onClick={() => onSchoolClicked(school)} w='full'>
        {isDesktop ? (
          <SchoolItem
            hideArrow={hideArrow}
            school={school}
            selected={current}
            checkbox={![Mode.LIST, Mode.SELECT_INTERESTED_DANCERS].includes(mode)}
          />
        ) : (
          <MobileSchoolItem
            hideArrow={hideArrow}
            school={school}
            selected={current}
            checkbox={![Mode.LIST, Mode.SELECT_INTERESTED_DANCERS].includes(mode)}
          />
        )}
      </Box>
    )
  }

  const onDancerClicked = (dancer: Dancer) => {
    if (mode === Mode.LIST) {
      const route = ROUTE_DETAILS_BASE(ROUTE_DANCER.replace(':id', dancer.id.toString()))
      navigate(route)
    } else if (mode === Mode.SELECT_DANCER) {
      setSelectedDancers!(
        selectedDancers!.find(s => s.id === dancer.id)
          ? selectedDancers!.filter(s => s.id !== dancer.id)
          : [...selectedDancers!, dancer],
      )
    }
  }

  const DancerWrapper = (dancer: Dancer, id: string, hideArrow?: boolean) => {
    let current
    if (mode === Mode.LIST) {
      current = location.pathname.includes('dancer') && dancer.id.toString() === id
    } else {
      current = Boolean(selectedDancers?.find(i => i.id === dancer.id))
    }

    const key = `dancer_${dancer.id}`
    return (
      <Box key={key} as={isDesktop ? Link : Box} onClick={() => onDancerClicked(dancer)} w='full'>
        {isDesktop ? (
          <DancerItem
            dancer={dancer}
            selected={current}
            checkbox={![Mode.LIST, Mode.SELECT_INTERESTED_DANCERS].includes(mode)}
            onAddInterested={mode === Mode.SELECT_INTERESTED_DANCERS ? onAddInterested : undefined}
            interested={Boolean(
              dancer.schools?.find(s => s.id.toString() === (id || currentSchool?.id.toString())),
            )}
            hideArrow={hideArrow}
          />
        ) : (
          <MobileDancerItem
            dancer={dancer}
            selected={current}
            checkbox={![Mode.LIST, Mode.SELECT_INTERESTED_DANCERS].includes(mode)}
            interested={Boolean(
              dancer.schools?.find(s => s.id.toString() === (id || currentSchool?.id.toString())),
            )}
            hideArrow={hideArrow}
          />
        )}
      </Box>
    )
  }

  return {placeholder, DancerWrapper, SchoolWrapper, toggleSelect}
}

export default useItemList
