import {BoxProps, Center, VStack} from '@chakra-ui/react'
import omit from 'lodash/omit'
import {ReactNode} from 'react'
import BoxCard from './BoxCard'

export const BoxDetail = (props: BoxProps & {children: ReactNode[] | ReactNode}) => (
  <BoxCard {...omit(props, 'children')} alignSelf='stretch'>
    <Center h='full'>
      <VStack spacing={0} alignItems='start' w='full'>
        {props.children}
      </VStack>
    </Center>
  </BoxCard>
)
