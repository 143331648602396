import {Box, Flex, HStack, IconButton, useTheme} from '@chakra-ui/react'
import {SingleChildrenProps} from 'models/interfaces'
import React, {ReactNode} from 'react'
import {RiLogoutBoxRLine} from 'react-icons/ri'
import {logout} from '../../utils/dataHelper'
import {hexToRGBA} from 'lib/utils'

interface Props extends SingleChildrenProps {
  header?: ReactNode | ReactNode[]
  footer?: ReactNode | ReactNode[]
}

const MobileLayout = ({header, footer, children}: Props) => {
  const theme = useTheme()
  return (
    <Flex
      w='full'
      h='full'
      justifyContent='center'
      bgGradient={`linear(to-t, ${hexToRGBA('#94a3b8', 0.5)}, ${hexToRGBA('#e2e8f0', 0.5)})`}
      // bg='brand.surface02'
      // backgroundColor='rgba(0, 0, 0, 0.8)' // This creates a translucent background
      // backdropFilter='blur(100px)' // This blurs the content behind this element
    >
      <Flex w='full' maxW='3xl' bg='white'>
        <Flex flexDir='column' minH='100vh' maxH='100vh' w='full'>
          <Box p={4} w='full' bgColor={theme.colors.brand.surface04}>
            <HStack justify='space-between'>
              {header}
              <IconButton
                aria-label='logout'
                color='brand.accent'
                variant='primary'
                onClick={logout}
                icon={<RiLogoutBoxRLine />}
              />
            </HStack>
          </Box>
          <Box flex={1} overflowY='scroll' py={4}>
            {children}
          </Box>
          {footer && (
            <Box p={4} w='full' bgColor={theme.colors.brand.surface04}>
              {footer}
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MobileLayout
