import {
  Button,
  Center,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react'
import {School} from 'models'
import {useEffect, useState} from 'react'
import {RiAddLine} from 'react-icons/ri'
import usePhaseData from '../../hooks/usePhaseData'
import {$, k} from '../../i18n/localization'
import ItemList from '../Common/ItemList'
import {useNavigation, useParams, useSearchParams} from 'react-router-dom'
import Store from 'lib/utils/Store'

interface Props {
  selected: School[]
  saveSchools: (newSchools: School[]) => void
}

const AddEditSchools = ({selected, saveSchools}: Props) => {
  const [selectedSchools, setSelectedSchools] = useState(selected)
  const {isOpen, onOpen, onClose} = useDisclosure({
    // defaultIsOpen: Store.preserveModalState.isOpen.peek(),
  })
  const {disableAdminEditFeatures} = usePhaseData()

  useEffect(() => {
    Store.preserveModalState.isOpen.set(isOpen)
  }, [isOpen])

  useEffect(() => {
    setSelectedSchools(selected)
  }, [selected])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={['full', '2xl']}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{$(k.editSchools)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={['80vh', '70vh']} overflowY='scroll' py={0}>
            <ItemList
              hideArrow={true}
              selectedSchools={selectedSchools}
              setSelectedSchools={setSelectedSchools}
            />
          </ModalBody>

          <ModalFooter>
            <Flex gap={5} w='full' justifyContent='space-evenly'>
              <Button variant='outline' onClick={onClose} flex={1}>
                {$(k.cancel)}
              </Button>
              <Button
                variant='primary'
                onClick={() => {
                  saveSchools(selectedSchools)
                  onClose()
                }}
                flex={1}>
                {$(k.edit)}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {disableAdminEditFeatures ? (
        <Spacer />
      ) : (
        <Center bgColor='gray.100' w='full' onClick={onOpen} py={4} borderRadius='xl'>
          <IconButton
            bgColor='white'
            variant='outline'
            aria-label='add edit'
            icon={<RiAddLine />}
          />
        </Center>
      )}
    </>
  )
}

export default AddEditSchools
