import Strapi from 'strapi-sdk-js'
import {isDev} from '../const'
import {logout} from 'lib/utils/dataHelper'

const apiUrl = import.meta.env.VITE_API_BASE_URL

const BASE_URL = isDev ? 'http://127.0.0.1:1337' : apiUrl ?? 'https://dashboard.prixdelausanne.org/'

const strapi = new Strapi({url: BASE_URL})

strapi.axios.interceptors.response.use(
  response => response,
  error => {
    console.log('axios error', error)
    if (error?.status === 401 || error.response?.status === 401) {
      logout()
    }
    return Promise.reject(error)
  },
)

export default strapi
