import {
  Button,
  Heading,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import {enableReactTracking} from '@legendapp/state/config/enableReactTracking'
import Store from 'lib/utils/Store'
import React from 'react'
import {FaCaretRight, FaRegCircleCheck} from 'react-icons/fa6'
import {IoDiamondOutline} from 'react-icons/io5'
import {RiLockLine} from 'react-icons/ri'
import usePhaseData from '../../hooks/usePhaseData'
import {$, k} from '../../i18n/localization'

enableReactTracking({
  auto: true,
})

const PhaseStepper = () => {
  const isSignedIn = Store.isSignedIn.use()
  const theme = useTheme()
  const dancers = Store.dancers.use()
  const schools = Store.schools.use()
  const {phaseIndex, onLock} = usePhaseData()

  const dancerWhoHaveConfirmed = dancers.filter(d => d.confirmed).length
  const schoolWhoHaveConfirmed = schools.filter(s => s.confirmed).length
  return (
    isSignedIn && (
      <HStack>
        <Popover trigger='hover'>
          <PopoverTrigger>
            <HStack
              borderRadius='full'
              py={1.5}
              px={3}
              border='solid 1px'
              borderColor={
                phaseIndex === 0 ? theme.colors.brand.accent : theme.colors.brand.text03
              }>
              <Text
                px={4}
                bgColor={
                  phaseIndex === 0 ? theme.colors.brand.accent : theme.colors.brand.surface03
                }
                borderRadius='full'
                fontSize='sm'>
                {$(k.phase)} 1
              </Text>
              <Text color='white'>{$(k.dancerChoice)}</Text>
              {phaseIndex > 0 && <FaRegCircleCheck color='white' />}
            </HStack>
          </PopoverTrigger>
          <PopoverContent bgColor='white' borderRadius='2xl'>
            <VStack p={4}>
              <Heading>{dancers.length} dancers</Heading>
              <Text color='brand.text02'>
                {dancerWhoHaveConfirmed}{' '}
                {dancerWhoHaveConfirmed > 1 ? $(k.selections) : $(k.selection)}
              </Text>
              <Button
                w='full'
                variant='gold'
                rightIcon={<RiLockLine />}
                onClick={onLock}
                isDisabled={phaseIndex !== 0}>
                {$(k.lockSelection)}
              </Button>
            </VStack>
          </PopoverContent>
        </Popover>

        <FaCaretRight color={theme.colors.brand.text03} />

        <Popover trigger='hover'>
          <PopoverTrigger>
            <HStack
              borderRadius='full'
              py={1.5}
              px={3}
              border='solid 1px'
              borderColor={
                phaseIndex === 1 ? theme.colors.brand.accent : theme.colors.brand.text03
              }>
              <Text
                px={4}
                bgColor={
                  phaseIndex === 1 ? theme.colors.brand.accent : theme.colors.brand.surface03
                }
                borderRadius='full'
                fontSize='sm'>
                {$(k.phase)} 2
              </Text>
              <Text color='white'>{$(k.partnersChoice)}</Text>
              {phaseIndex > 1 && <FaRegCircleCheck color='white' />}
            </HStack>
          </PopoverTrigger>

          <PopoverContent bgColor='white' borderRadius='2xl'>
            <VStack p={4}>
              <Heading>{schools.length} schools</Heading>
              <Text color='brand.text02'>
                {schoolWhoHaveConfirmed}{' '}
                {schoolWhoHaveConfirmed > 1 ? $(k.selections) : $(k.selection)}{' '}
              </Text>
              <Button
                w='full'
                variant='gold'
                rightIcon={<RiLockLine />}
                onClick={onLock}
                isDisabled={phaseIndex !== 1}>
                {$(k.lockSelection)}
              </Button>
            </VStack>
          </PopoverContent>
        </Popover>

        <FaCaretRight color={theme.colors.brand.text03} />

        <Popover trigger='hover'>
          <PopoverTrigger>
            <HStack
              borderRadius='full'
              py={1.5}
              px={3}
              border='solid 1px'
              borderColor={
                phaseIndex === 2 ? theme.colors.brand.accent : theme.colors.brand.text03
              }>
              <Text
                px={4}
                bgColor={
                  phaseIndex === 2 ? theme.colors.brand.accent : theme.colors.brand.surface03
                }
                borderRadius='full'
                fontSize='sm'>
                {$(k.phase)} 3
              </Text>
              <Text color='white'>{$(k.publishResults)}</Text>
              {phaseIndex > 2 && <FaRegCircleCheck color='white' />}
            </HStack>
          </PopoverTrigger>

          <PopoverContent bgColor='white' borderRadius='2xl'>
            <VStack p={4}>
              <Heading>{$(k.results)}</Heading>
              <Text color='brand.text02'>{$(k.publishAllResults)}</Text>
              <Button
                w='full'
                variant='gold'
                rightIcon={<IoDiamondOutline />}
                onClick={onLock}
                isDisabled={phaseIndex !== 2}>
                {$(k.publish)}
              </Button>
            </VStack>
          </PopoverContent>
        </Popover>
      </HStack>
    )
  )
}

export default PhaseStepper
