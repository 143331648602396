const CustomButton = {
  baseStyle: {
    borderRadius: 'full',
    fontWeight: 'bold',
    color: 'white',
    _focus: {
      ring: 'none',
    },
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    primary: {
      px: '16px',
      py: '6px',
      color: 'white',
      backgroundColor: 'brand.interactive',
      _hover: {
        backgroundColor: 'brand.interactive',
        filter: 'brightness(85%)',
      },
      _active: {
        backgroundColor: 'brand.interactive',
      },
    },
    secondary: {
      px: '16px',
      py: '6px',
      color: 'white',
      backgroundColor: 'brand.surface03',
    },
    gold: {
      bgColor: 'brand.accent',
      color: 'white',
      _hover: {
        bgColor: 'brand.accent !important',
        filter: 'brightness(85%)',
      },
    },
    linkGold: {
      border: 'solid 1px',
      borderColor: 'brand.accent',
      color: 'brand.accent',
      _hover: {
        filter: 'brightness(85%)',
      },
    },
    linkWebsite: {
      border: 'solid 1px',
      borderColor: 'brand.interactive',
      color: 'brand.interactive',
      _hover: {
        filter: 'brightness(85%)',
      },
    },
    link: {
      textDecoration: 'underline',
      _hover: {
        filter: 'brightness(85%)',
      },
    },
    danger: {
      px: '16px',
      py: '6px',
      color: 'white',
      backgroundColor: 'brand.error',
    },
    outline: {
      _hover: {backgroundColor: 'transparent !important', filter: 'brightness(85%)'},
      _active: {
        backgroundColor: 'transparent !important',
        filter: 'brightness(85%)',
      },
    },
    ghost: {
      _hover: {backgroundColor: 'transparent !important', filter: 'brightness(85%)'},
      _active: {
        backgroundColor: 'transparent !important',
        filter: 'brightness(85%)',
      },
    },
  },
}

export default CustomButton
