import {AspectRatio, Avatar, Divider, Heading, HStack, Text, VStack} from '@chakra-ui/react'
import {Select} from 'chakra-react-select'
import {Dancer} from 'models'
import {prizes} from 'models/interfaces'
import {RiUserLine} from 'react-icons/ri'
import {useParams} from 'react-router-dom'
import strapi from '../../api'
import {$, k} from '../../i18n/localization'
import {NAVBAR_HEIGHT_WITH_PADDING} from '../../styles/theme'
import {getAge, getSeniority} from '../../utils/dancerHelper'
import Store from '../../utils/Store'
import {BoxDetail} from '../Common/BoxDetail'
import SchoolSelection from '../School/SchoolSelection'
import {useObservable, useObserve} from '@legendapp/state/react'

const DancerDetails = () => {
  const {id} = useParams()
  const dancerObs = Store.dancers.find(s => s.id.toString() === id)!
  const dancer = dancerObs.peek()
  const Eligible_prix = dancerObs.Eligible_prix.use() || []
  useObserve(() => {
    const newValue = dancerObs.get()

    // update only if changed
    if (JSON.stringify(newValue.Eligible_prix) === JSON.stringify(dancer.Eligible_prix)) return
    strapi.update<Dancer>('dancers', newValue.id, {
      Eligible_prix: newValue.Eligible_prix,
    })
  })

  return (
    <VStack alignItems='start' spacing={5}>
      <HStack w='full'>
        <AspectRatio ratio={1} w='290px'>
          <Avatar
            src={dancer.Profile_Picture}
            bgColor='gray.400'
            borderRadius='2xl'
            icon={<RiUserLine size='120' />}
          />
        </AspectRatio>
        <VStack flex={1}>
          <HStack w='full' flex={1}>
            <BoxDetail h='full'>
              <Text>Name</Text>
              <HStack alignContent='end'>
                <Heading>
                  {dancer.Firstname} {dancer.Lastname}
                </Heading>
                <Heading as='h4'>{dancer.REF}</Heading>
              </HStack>
            </BoxDetail>
            <BoxDetail>
              <Text>School</Text>
              <Heading as='h4'>{dancer.Current_school || '-'}</Heading>
            </BoxDetail>
          </HStack>
          <HStack w='full'>
            <BoxDetail>
              <Text>{$(k.age)}</Text>
              <Heading as='h4'>{getAge(dancer)}</Heading>
            </BoxDetail>
            <BoxDetail>
              <Text>{$(k.nationality)}</Text>
              <Heading as='h4'>{dancer.Nationalities}</Heading>
            </BoxDetail>
            <BoxDetail>
              <Text>Gender</Text>
              <Heading as='h4'>{dancer.Gender}</Heading>
            </BoxDetail>
            <BoxDetail>
              <Text>{$(k.category)}</Text>
              <Heading as='h4'>{getSeniority(dancer)}</Heading>
            </BoxDetail>
          </HStack>
          <HStack w='full'>
            <BoxDetail>
              <Text>Variation</Text>
              <Heading as='h4'>{dancer.Classical_variation}</Heading>
            </BoxDetail>
            <BoxDetail>
              <Text my={4}>Eligible for</Text>
              <Select
                isMulti
                options={prizes.map(p => ({value: p, label: p}))}
                placeholder='Select prizes'
                variant='outline'
                tagVariant='subtle'
                onChange={value => {
                  const newValue = value.map((v: any) => v.value)
                  dancerObs.set({
                    ...dancer,
                    Eligible_prix: newValue,
                  })
                }}
                chakraStyles={{
                  option: styles => ({
                    ...styles,
                    color: 'black',
                  }),
                  dropdownIndicator: () => ({
                    display: 'none',
                  }),
                  menuList: styles => ({...styles, backgroundColor: 'white'}),
                  menu: styles => ({...styles, zIndex: 4}),
                  valueContainer: styles => ({...styles, maxH: 10, minW: 250, overflowY: 'scroll'}),
                }}
                closeMenuOnSelect={false}
                defaultValue={Eligible_prix.map((p: string) => ({value: p, label: p}))}
              />
            </BoxDetail>
          </HStack>
        </VStack>
      </HStack>
      <VStack
        spacing={5}
        w='full'
        maxH={`calc(100vh - ${NAVBAR_HEIGHT_WITH_PADDING}px - 315px)`}
        overflowY='scroll'>
        <Divider />
        <SchoolSelection dancer={dancerObs} />
      </VStack>
    </VStack>
  )
}

export default DancerDetails
