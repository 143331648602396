import {StackProps, VStack} from '@chakra-ui/react'
import {SingleChildrenProps} from 'models/interfaces'

const VStackDark = (props: StackProps & SingleChildrenProps) => (
  <VStack
    spacing={5}
    w='full'
    borderRadius='2xl'
    px={[3, 20]}
    py={5}
    bgColor='brand.dark'
    {...props}
  />
)

export default VStackDark
