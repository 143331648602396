const CustomInput = {
  variants: {
    text: {
      field: {
        borderRadius: 'full',
        fontWeight: 'bold',
        my: '0.25rem',
        bg: 'brand.surface02',
        color: 'brand.text03',
        _placeholder: {
          color: 'brand.text04',
        },
      },
    },
    search: {
      field: {
        borderRadius: 'full',
        bg: 'white',
        color: 'brand.text03',
        border: '1px solid',
        borderColor: 'brand.surface02',
        _placeholder: {
          color: 'brand.text03',
        },
      },
    },
  },
  defaultProps: {
    variant: 'text',
  },
}

export default CustomInput
