const globalStyles = {
  global: () => ({
    body: {
      color: 'brand.text02 !important',
    },
    h1: {
      fontSize: '1.5rem !important',
    },
    h2: {
      fontSize: '1.25rem !important',
    },
    h3: {
      fontSize: '1.15rem !important',
    },
    h4: {
      fontSize: '1rem !important',
    },
    'h1, h2, h3, h4': {
      color: 'black',
    },
    a: {
      color: 'brand.primary !important',
      _hover: {
        textDecoration: 'none !important',
      },
    },
  }),
}

export default globalStyles
