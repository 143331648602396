import {Box, Flex, useTheme} from '@chakra-ui/react'
import React from 'react'
import {Outlet} from 'react-router-dom'
import {NAVBAR_HEIGHT_WITH_PADDING} from '../../styles/theme'
import ItemList from './ItemList'

const Sidebar = () => {
  const theme = useTheme()
  return (
    <Flex p={3} gap={3} w='full'>
      <Box
        borderColor={theme.colors.brand.surface02}
        borderWidth={10}
        px={3}
        borderRadius='3xl'
        flex={1}
        maxH={`calc(100vh - ${NAVBAR_HEIGHT_WITH_PADDING}px)`}
        data-testid='sidebar'
        overflowY='scroll'>
        <ItemList padding={8} />
      </Box>
      <Box flex={3}>
        <Outlet />
      </Box>
    </Flex>
  )
}

export default Sidebar
