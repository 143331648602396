const colors = {
  brand: {
    surfaceSuccess: '#dcfce7',
    success: '#16a34a',
    error: '#f43f5e',
    text04: '#94a3b8',
    text02: '#1e293b',
    text01: '#020617',
    text03: '#475569',
    interactive: '#1e293b',
    surface01: '#fff',
    surface02: '#e2e8f0',
    surface03: '#94a3b8',
    surface04: '#1e293b',
    warning: '#eab308',
    accent: '#c9ae6f',
    matchingBg: '#FCF9F2',
  },
}

export default colors
