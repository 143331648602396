import {
  AspectRatio,
  Avatar,
  Box,
  Center,
  Heading,
  HStack,
  Text,
  useTheme,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {ObservableObject} from '@legendapp/state'
import isEmpty from 'lodash/isEmpty'
import {Dancer, School} from 'models'
// import dynamic from 'next/dynamic'
import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd'
import {RiBankLine} from 'react-icons/ri'
import {useParams} from 'react-router-dom'
import strapi from '../../api'
import usePhaseData from '../../hooks/usePhaseData'
import {$, k} from '../../i18n/localization'
import {FLOATING_HEADER_HEIGHT} from '../../styles/theme'
import {swapArray} from '../../utils/dataHelper'
import Store from '../../utils/Store'
import {getItemStyle, getListStyle} from '../Common/dragAndDropStyles'
import {handleError} from '../Common/handleError'
import AddEditSchools from '../Dancer/AddEditSchools'
import SchoolItem from './SchoolItem'
import {DancerInterestedSchools, DancersMatches} from 'lib/pages/dancers/dancer-results'

interface Props {
  dancer: ObservableObject<Dancer>
}

const SchoolSelection = ({dancer}: Props) => {
  const {id} = useParams()
  const schools = dancer.schools.use()
  const theme = useTheme()
  const toast = useToast()
  const {disableAdminEditFeatures} = usePhaseData()

  const saveSchools = async (newSchools: School[], onDrag: boolean = false) => {
    try {
      await strapi.update<Dancer>('dancers', id as string, {
        schools: newSchools.map(s => s.id),
      })
      if (onDrag) return
      dancer.schools.set(newSchools)
      // We need to update the school reciprocally since we handle locally
      const storedSchools = Store.schools
      newSchools.forEach(s => {
        const storedSchool = storedSchools.find(school => school.peek().id === s.id)
        if (storedSchool) {
          storedSchool.dancers.set([...storedSchool.dancers.peek(), dancer.peek()])
        }
      })
      toast({
        title: $(k.schoolsUpdated),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      handleError(e)
    }
  }

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return
    const newSchools = swapArray(schools, result.source.index, result.destination.index)
    dancer.schools.set(newSchools)
    await saveSchools(newSchools, true)
  }

  const currentDancer = dancer.use()
  return (
    <VStack alignItems='start' w='full' spacing={4}>
      <DancersMatches dancer={currentDancer} />
      <HStack pos='sticky' top={0} bgColor='white' w='full' zIndex={1} h={FLOATING_HEADER_HEIGHT}>
        <Heading>Chosen schools</Heading>
        {!disableAdminEditFeatures && <Text>{$(k.clickDragDesc)}</Text>}
      </HStack>
      <Box px={2}>
        <Text fontStyle='italic' color={theme.colors.brand.text03} fontSize='xs'>
          Rank the schools by preferences
        </Text>
      </Box>
      <Center px={2} w='full'>
        <Box w='full' boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'>
          {isEmpty(schools) ? (
            <Center p={10}>
              <VStack>
                <AspectRatio ratio={1} w='100px'>
                  <Avatar borderRadius='full' icon={<RiBankLine size='60' />} />
                </AspectRatio>
                <Text>{$(k.noSchoolsPlaceholder)}</Text>
              </VStack>
            </Center>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver, theme.colors.brand.surface02)}>
                    {schools.map((item, index) => (
                      <Draggable
                        key={item.id.toString()}
                        isDragDisabled={disableAdminEditFeatures}
                        draggableId={item.id.toString()}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}>
                            <SchoolItem school={item} draggable />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Box>
      </Center>
      <AddEditSchools selected={schools} saveSchools={saveSchools} />
      <DancerInterestedSchools dancer={currentDancer} />
    </VStack>
  )
}

export default SchoolSelection
