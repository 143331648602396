import {Alert, AlertIcon, Button, Center, Image, Text} from '@chakra-ui/react'
import {RiLogoutBoxRLine} from 'react-icons/ri'
import {$, k} from '../../i18n/localization'
import {logout} from '../../utils/dataHelper'
import VStackDark from './VStackDark'

export const NotSupported = ({platform}: {platform: string}) => (
  <Center>
    <VStackDark spacing={10}>
      <Image src='/assets/logo_circled.svg' alt='logo' />
      <Text maxW='90%' textAlign='center'>
        {$(k.welcomeMessage)}
      </Text>
      <Alert status='warning'>
        <AlertIcon />
        <Text textAlign='center'>
          {platform} {$(k.notSupported)}
        </Text>
      </Alert>
      <Button variant='primary' onClick={logout} rightIcon={<RiLogoutBoxRLine />}>
        {$(k.logout)}
      </Button>
    </VStackDark>
  </Center>
)
