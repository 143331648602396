import LocalizedStrings from 'react-localization'
import {en} from './en'
import {fr} from './fr'

export const k = new LocalizedStrings({
  en: en,
  fr: fr,
})

export const $ = (stringKey: string, ...rest: any) => k.formatString(stringKey, ...rest)
